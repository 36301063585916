import styled, { css } from 'styled-components';

const Item = styled.div.withConfig({
  shouldForwardProp: (prop) => ['children'].includes(prop),
})`
  order: 0;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  display: block;

  ${(props) =>
    props.inlineBlock &&
    css`
      display: inline-block;
    `};

  ${(props) =>
    props.inlineFlex &&
    css`
      display: inline-flex;
    `};

  ${(props) =>
    props.flex &&
    css`
      display: flex;
    `};

  /* order */
  ${(props) =>
    props.order &&
    css`
      order: ${props.order};
    `};

  /* flex-basis */
  ${(props) =>
    props.basis &&
    css`
      flex-basis: ${props.basis};
    `};

  /* flex-grow */
  ${(props) =>
    props.grow &&
    css`
      flex-grow: ${props.grow};
    `};

  /* flex-shrink */
  ${(props) =>
    props.shrink &&
    css`
      flex-shrink: ${props.shrink};
    `};

  ${(props) =>
    props.noShrink &&
    css`
      flex-shrink: 0;
    `};
`;

export default Item;