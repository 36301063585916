import styled, { css } from 'styled-components';

const Flex = styled.div.withConfig({
  shouldForwardProp: (prop) => ['children'].includes(prop),
})`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;

  /* display */
  ${(props) =>
    props.inline &&
    css`
      display: inline-flex;
    `};

  /* flex-direction */
  ${(props) =>
    props.row &&
    css`
      flex-direction: row;
    `};
  ${(props) =>
    props.rowReverse &&
    css`
      flex-direction: row-reverse;
    `};
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
    `};
  ${(props) =>
    props.columnReverse &&
    css`
      flex-direction: column-reverse;
    `};

  /* flex-wrap */
  ${(props) =>
    props.nowrap &&
    css`
      flex-wrap: nowrap;
    `};
  ${(props) =>
    props.wrap &&
    css`
      flex-wrap: wrap;
    `};
  ${(props) =>
    props.wrapReverse &&
    css`
      flex-wrap: wrap-reverse;
    `};

  /* justify-content */
  ${(props) =>
    props.justifyStart &&
    css`
      justify-content: flex-start;
    `};
  ${(props) =>
    props.justifyEnd &&
    css`
      justify-content: flex-end;
    `};
  ${(props) =>
    props.justifyCenter &&
    css`
      justify-content: center;
    `};
  ${(props) =>
    props.justifyBetween &&
    css`
      justify-content: space-between;
    `};
  ${(props) =>
    props.justifyAround &&
    css`
      justify-content: space-around;
    `};

  /* align-content */
  ${(props) =>
    props.contentStart &&
    css`
      align-content: flex-start;
    `};
  ${(props) =>
    props.contentEnd &&
    css`
      align-content: flex-end;
    `};
  ${(props) =>
    props.contentCenter &&
    css`
      align-content: center;
    `};
  ${(props) =>
    props.contentSpaceBetween &&
    css`
      align-content: space-between;
    `};
  ${(props) =>
    props.contentSpaceAround &&
    css`
      align-content: space-around;
    `};
  ${(props) =>
    props.contentStretch &&
    css`
      align-content: stretch;
    `};

  /* align-items */
  ${(props) =>
    props.alignStart &&
    css`
      align-items: flex-start;
    `};
  ${(props) =>
    props.alignEnd &&
    css`
      align-items: flex-end;
    `};
  ${(props) =>
    props.alignCenter &&
    css`
      align-items: center;
    `};
  ${(props) =>
    props.alignBaseline &&
    css`
      align-items: baseline;
    `};
  ${(props) =>
    props.alignStretch &&
    css`
      align-items: stretch;
    `};

  /* utilities */
  ${(props) =>
    props.full &&
    css`
      width: 100%;
      height: 100%;
      flex-basis: 100%;
    `};
  ${(props) =>
    props.content &&
    css`
      padding-top: 100px;
      padding-bottom: 30px;
      width: 100%;
      height: 100%;
      flex-basis: 100%;
    `};
  ${(props) =>
    props.center &&
    css`
      align-items: center;
      justify-content: center;
    `};
`;

export default Flex;