import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Flex } from '../../components/flex';

const markDown = `
# Summary API

A RESTful API powers the UI behind this project. One can access the API with the following url: https://api.smrzr.io/v1 or https://private-api.smrzr.io/v1. The private api allows for up to 100,000 monthly requests. If you need more than this, please email derekmiller1020@gmail.com. To access the private api, please click the button at the bottom of the page. You will immediately get an email within 5 minutes that includes the api key.

### Query Params

To summarize a piece of text, you can send a POST request to https://api.smrzr.io/v1/summarize or https://private-api.smrzr.io/v1/summarize with a raw/text content-type with the full summary. You can also tweak the following parameters:

* num_sentences: The number of sentences you want for the summary.
* min_length: The minimum char length that a sentence needs to be in order to qualify in the summary.
* max_length: The maximum char length that a sentence needs to be in order to qualify in the summary.
* algorithm: This corresponds to the clustering algorithm to use. Currently, only \`kmeans\` and \`gmm\` are supported.

### Credentials Setup

If you are using the private endpoints, you will need to use an api key. Below shows an example with your api key.
\`\`\`python 
import requests

headers = {
    "api_token": "<your_private_key>"
}

body = '''
<Your text you want to summarize>
'''

resp = requests.post(
    'https://private-api.smrzr.io/v1/summarize?num_sentences=5&algorithm=kmeans&min_length=40&max_length=500', 
    headers=headers,
    data=body
)

\`\`\`


### API Python Example with Query Params
\`\`\`python 
import requests
\`\`\`

\`\`\`
body = '''
<Your text you want to summarize>
'''
\`\`\`

\`\`\`
resp = requests.post('https://api.smrzr.io/v1/summarize?num_sentences=5&algorithm=kmeans&min_length=40&max_length=500', data=body)
\`\`\`

\`\`\`
summary = resp.json()['summary']

\`\`\`

## Summarize New Articles

The summarizer API also includes a place where you can summarize news articles. This includes a custom text processor to attempt to remove unrelated content before summarization. The endpoint is https://api.smrzr.io/v1/summarize/news . The same arguments can be used as above with this endpoint. 

### News Summary Example

Below shows an example of using the news summary endpoint. Again, if you want to use the private endpoints you just need to replace https://api.smrzr.io/v1 with https://private-api.smrzr.io/v1. 

\`\`\`python
import requests
json_body = {
    "url": "news_url"
}

resp = requests.post("https://api.smrzr.io/v1/summarize/news?num_sentences=5&min_length=40", json=json_body).json()
summary = resp['summary']
\`\`\`

`;


const About = () => (<div>
    <ReactMarkdown children={markDown}>
    </ReactMarkdown>

    <Flex column alignCenter>
        <div>
        </div>
      </Flex>
</div>);

export default About;
